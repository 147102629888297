import React, { useEffect } from 'react';
import HtmlHead from '../components/html-head';
import HeroLandingComponent from '../components/landing-components/hero-landing';
import LandingNav from '../components/landing-components/nav.tsx';
import AuthorSection from '../components/landing-components/author-section';
import heroimg from '../assets/landing-page/ZD Embed 1.png';
import catherine from '../assets/landing-page/Catherine Coleman.png';
import charles from '../assets/landing-page/Charles Coaxum.png';
import everbridge from '../assets/landing-page/everbridge.png';
import cisco from '../assets/landing-page/cisco.png';
import LandingFooter from '../components/landing-components/landing-footer';
import LandingCtaBlock from '../components/landing-components/landing-cta';
import { graphql } from 'gatsby';
import { PageData } from '../types';

const Zendesk: React.FC<PageData> = (resp) => {
    const seoData = resp?.data?.allContentfulPageSeoData?.edges[0]?.node;

    const hero = {
        formName: 'ai-for-zendesk',
        title: 'Love <span>Zendesk</span> Even More',
        image: heroimg,
        formId: 'cd98683a-3f5b-4d0e-9b7d-69cf5dc35ab8'
    };

    const author = [
        {
            name: '<span>Catherine</span> Coleman',
            title: 'Former VP Global Customer Support',
            quote: "With AptEdge, we’re delivering higher quality answers, reaching <span class='quote-num'>99% </span><span class='quote-imp'>CSAT</span>",
            photo: catherine,
            company: everbridge
        },
        {
            name: '<span>Charles</span> Coaxum',
            title: 'Former Head of CS Security',
            quote: "AptEdge provides fast accurate answers, improving <span class='quote-imp'>Resolution Time</span> up by <span class='quote-num'>40% </span>",
            photo: charles,
            company: cisco
        },
    ]

    return (
        <>
            <HtmlHead
                title={seoData?.title}
                metaDescription={seoData?.metaDescription?.metaDescription}
                canonicalLink={seoData?.canonicalLink}
                openGraphImage={seoData?.openGraphImage?.file?.url}
            />
            <LandingNav/>
            <HeroLandingComponent hero={hero} />
            <AuthorSection author={author}/>
            <LandingCtaBlock/>
            <LandingFooter/>
            {/* @ts-ignore */}
            <style jsx>
                {
                `.intercom-lightweight-app {
                    display: none !important;
                }`
                }
            </style>
        </>
    );
}

export default Zendesk;

export const query = graphql`
    query ZendeskQuery {
        allContentfulPageSeoData(filter: { pagePath: { eq: "/ai-for-zendesk" } }) {
            edges {
                node {
                    title
                    canonicalLink
                    metaDescription {
                        metaDescription
                    }
                    openGraphImage {
                        file {
                            url
                        }
                    }
                }
            }
        }
    }
`;